<template>
  <div class="list">
    <div class="list_b">
      <ul>
        <li>
          <p>账号：</p>
          <input type="text" @keypress.enter="getData(1)" v-model="form.userName">
        </li>
        <li>
          <p>姓名：</p>
          <input type="text" @keypress.enter="getData(1)" v-model="form.xingming">
        </li>
        <li>
          <button type="button" @click="getData(1)">查询</button>
        </li>
        <div class="clear"></div>
      </ul>
    </div>
    <div class="home_list">
      <div class="list_d">
      <table>
        <thead>
          <tr>
            <td>登录账号</td>
            <td>店铺数上限</td>
            <td>员工数上限</td>
            <td>姓名</td>
            <td>性别</td>
            <td>门店</td>
            <td>状态</td>
          </tr>
        </thead>
        <tbody>
          <tr v-for="item in dataList" :key="item.id">
            <td>{{item.userName}}</td>
            <td>{{item.maxStore}}</td>
            <td>{{item.maxEmp>=50?'不限':item.maxEmp}}</td>
            <td>{{item.xingming}}</td>
            <td>{{item.xingbie}}</td>
            <td>{{item.fenpeimendianName}}</td>
            <td>{{countAdmin(item.admin)}}</td>
          </tr>
        </tbody>
      </table>
    </div>
    </div>
    <div class="list_c">
      <Page :pageData="pageData" @resetData="resetData"></Page>
      <div class="clear"></div>
    </div>
  </div>
</template>

<script>
import axios from '@/http/axios'
import moment from 'moment'
export default {
  name: 'packageSettings',
  data() {
    return {
      dataList:[],
      form:{
        userName:'',
        xingming:'',
      },
      pageData:{
        pageSize:20,
        pageIndex:1,
        total:0,
      },
    }
  },
  created() {
     this.getData(1)
  },
  components: {},
  mounted() {},
  methods: {
    countAdmin(admin){
      switch (admin){
        case 0:{
          return '已正式开通'
        }
        case 2:{
          return '试用中'
        }
        case 3:{
          return '延期试用中'
        }
        default:{
          return ''
        }
        
      }
    },
    getShengri(sr){
      if(sr) return moment(sr).format("YYYY-MM-DD")
      else return ''
    },
    getData(index){
      if(index) this.pageData.pageIndex=1
      axios.post('/manage/MyListPage',{...this.pageData,...this.form}).then((res) => {
        if(res.code===5000){
          this.dataList=res.data
          this.pageData.total=res.total
        }else{
          this.$Mesg({content: res.msg,errOrSuc: 'err'})
        }
      })
    },
    resetData(data){
      this.pageData=data
      this.getData()
    },
  },
}
</script>
